<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12" md="9">
        <v-card>
          <v-card-title>
            {{ $t('routes.users.add') }}
          </v-card-title>
          <v-divider />

          <div class="pa-5">
            <v-row>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="firstname.model"
                  :error-messages="$t(firstname.error, { field: $t('label.firstname') })"
                  :label="$t('label.firstname')"
                  hide-details="auto"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="middlename.model"
                  :label="$t('label.middlename')"
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col md="4" cols="12">
                <v-text-field
                  v-model="lastname.model"
                  :error-messages="$t(lastname.error, { field: $t('label.lastname') })"
                  :label="$t('label.lastname')"
                  hide-details="auto"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email.model"
                  :error-messages="$t(email.error, { field: $t('label.email') })"
                  :label="$t('label.email')"
                  hide-details="auto"
                  type="email"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="roles.model"
                  :items="role_items"
                  :item-text="item => item.translations[$i18n.locale].name"
                  return-object
                  :error-messages="$t(roles.error, { field: $t('label.role') })"
                  hide-details="auto"
                  chips
                  :label="$t('label.click_to_add_item', { item: $t('entity.role') })"
                  multiple
                  clearable
                />
              </v-col>
            </v-row>
          </div>
          <v-card-text>{{ $t('page.user.add_user_password_description') }}</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-text>
            <v-btn
              :disabled="loading"
              small
              :loading="loading"
              color="primary"
              type="submit"
              @click="addUser"
              block
              class="mb-3"
            >
              <v-icon class="mr-1">{{ mdiContentSaveOutline }}</v-icon>
              {{ $t('common.save') }}
            </v-btn>
            <v-btn small @click="$router.push('/users')" block outlined :disabled="loading">
              <v-icon class="mr-1" small>{{ mdiArrowULeftTop }}</v-icon>
              {{ $t('common.back') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mdiArrowULeftTop, mdiContentSaveOutline } from '@mdi/js'

export default {
  components: {},
  mounted() {
    this.$http('roles/assignable/index').then(res => {
      this.available_roles = res.data
    })
  },
  data() {
    return {
      loading: false,
      firstname: {
        model: '',
        error: '',
      },
      middlename: {
        model: '',
      },
      lastname: {
        model: '',
        error: '',
      },
      email: {
        model: '',
        error: '',
      },
      roles: {
        model: [],
        error: '',
      },

      available_roles: [],
      //icons
      mdiContentSaveOutline,
      mdiArrowULeftTop,
    }
  },
  computed: {
    role_items() {
      let result = JSON.parse(JSON.stringify(this.available_roles))
      if (this.roles.model) {
        this.roles.model.forEach(item => {
          result.push(item)
        })
      }
      return result
    },
  },
  methods: {
    addUser() {
      this.loading = true
      this.$http
        .post('users/create', {
          email: this.email.model,
          firstname: this.firstname.model,
          middlename: this.middlename.model,
          lastname: this.lastname.model,
          roles: this.rolesResult(),
        })
        .then(res => {
          Object.assign(this.firstname, {
            model: '',
            error: '',
          })
          this.middlename = ''
          Object.assign(this.lastname, {
            model: '',
            error: '',
          })
          Object.assign(this.email, {
            model: '',
            error: '',
          })
          this.$router.push({
            name: 'users.edit',
            params: { id: res.data.id },
          })
        })
        .catch(error => {
          const errors = error.response.data.error
          this.email.error = errors.email ? errors.email[0] : ''
          this.firstname.error = errors.firstname ? errors.firstname[0] : ''
          this.lastname.error = errors.lastname ? errors.lastname[0] : ''
          this.roles.error = errors.roles ? errors.roles[0] : ''
        })
        .finally(() => {
          this.loading = false
          document.activeElement.blur()
        })
    },
    rolesResult() {
      let result = []
      this.roles.model.forEach(role => {
        result.push(role.id)
      })
      return result
    },
  },
}
</script>

<style scoped>
.XGutter * {
  padding-right: 8px;
}
</style>
